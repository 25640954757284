'use client'

import { usePathname } from 'next/navigation'
import Script from 'next/script'

const FreshChat = () => {
  const pathname = usePathname()

  if (pathname.startsWith('/studio')) return null

  return (
    <Script
      id="freshchat"
      src="https://dishoom.freshchat.com/js/widget.js"
      strategy="lazyOnload"
      onLoad={() => {
        window.fcWidget.init({
          token: 'ac55eaaa-93c1-4724-9291-dde93cc48439',
          host: 'https://dishoom.freshchat.com',
        })
      }}
      onError={(e: Error) => {
        console.error('Script failed to load', e)
      }}
    />
    // <Script id="freshchat">{
    //   /*js*/ `
    //   function initFreshChat() {
    //     console.log('init');
    //     window.fcWidget.init({
    //         token: "ac55eaaa-93c1-4724-9291-dde93cc48439",
    //         host: "https://dishoom.freshchat.com"
    //     });
    //   }
    //   function initialize(i,t){var e;i.getElementById(t)?initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,e.src="https://dishoom.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}function initiateCall(){initialize(document,"Freshchat-js-sdk")}window.addEventListener?window.addEventListener("load",initiateCall,!1):window.attachEvent("load",initiateCall,!1);`
    // }</Script>
  )
}

export default FreshChat
