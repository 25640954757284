'use client'

import Script from 'next/script'

const AtreemoTracking = () => {
  return (
    <>
      <Script
        id="atreemo-tracking-init"
        src="https://tracking.atreemo.com/Scripts/TrackingInit.js"
        onLoad={() => {
          if (typeof window.AtreemoTrackingLbr === 'undefined') return
          window.AtreemoTrackingLbr.init([
            'Dishoom Restaurants, https://dishoom.webecast.atreemo.uk',
          ])
        }}
      />
      <Script id="atreemo-tracking" src="https://tracking.atreemo.com/Scripts/Tracking.js"></Script>
    </>
  )
}

export default AtreemoTracking
